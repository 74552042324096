
export default {
  data() {
    return {
      refresh: false,
      menuOpen: false,
      mega_menu: this.prepareMega(),
      searchOpen: true,
      search: "",
      cart: { items: [] },
    };
  },
  watch: {
    "$nuxt.$route.hash": {
      handler(v) {
        this.menuOpen = false;
      },
      deep: true,
    },
    menuOpen: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    if (process.browser) {
      this.searchOpen = this.$store.getters.isMobile ? false : true;
      this.cart = this.$hyperCart.getCart();
      this.$nuxt.$on("updateCart", () => {
        this.cart = this.$hyperCart.getCart();
      });
    }
  },
  methods: {
    prepareMega() {
      let temp = JSON.parse(
        JSON.stringify(this.$store.getters.store.eshop.mega_menu)
      );
      temp = temp.map((c) => {
        let full = false;
        c.children.forEach((cc) => {
          if (cc.children && cc.children.length) {
            full = true;
          }
        });
        c.full = full;
        return c;
      });
      return temp;
    },
    doSearch() {
      let query = [];
      if (this.search) {
        query.push(`q=${encodeURIComponent(this.search)}`);
      }

      this.searchFocus = false;
      this.$router.push({ path: `/search/+${query.join("+")}` });
    },
  },
};
